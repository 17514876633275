import React from 'react'
import { AUTH_PREFIX_PATH, DASHBOARD_PREFIX_PATH } from 'configs/AppConfig'

export const openRoutes = [
    {
        key: 'auth_url',
        path: `/auth_url/*`,
        component: React.lazy(() => import('views/auth-from-url')),
    },
    {
        key: 'find-agents',
        path: `/find-agents`,
        component: React.lazy(() => import('views/public-views/find-agents')),
    },
    {
        key: 'agents-list',
        path: `/agents-list`,
        component: React.lazy(() => import('views/public-views/agents-list')),
    },
    {
        key: 'profile-details',
        path: `/profile-details`,
        component: React.lazy(() => import('views/public-views/profile-details')),
    },
    {
        key: 'listing-details',
        path: `/listing-details`,
        component: React.lazy(() => import('views/public-views/listing-details')),
    },
    {
        key: 'find-listings',
        path: `/find-listings`,
        component: React.lazy(() => import('views/public-views/find-listings')),
    },
    {
        key: 'listings-list',
        path: `/listings-list`,
        component: React.lazy(() => import('views/public-views/listings-list')),
    },
    {
        key: 'about-us',
        path: `/about-us`,
        component: React.lazy(() => import('views/public-views/about-us')),
    },
    {
        key: 'contact-us',
        path: `/contact-us`,
        component: React.lazy(() => import('views/public-views/contact-us')),
    },
]

export const protectedRoutes = [
    {
        key: 'upgrade-renew',
        path: `${DASHBOARD_PREFIX_PATH}/upgrade-renew`,
        component: React.lazy(() => import('views/dashboard-views/upgradeRenewPackage')),
        permissions: ['agent', 'agency', 'sub_agent'],
    },
    {
        key: 'dashboard',
        path: `${DASHBOARD_PREFIX_PATH}/`,
        component: React.lazy(() => import('views/dashboard-views/dashboard')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.profile',
        path: `${DASHBOARD_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/dashboard-views/profile')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.requests.profile',
        path: `${DASHBOARD_PREFIX_PATH}/requests/profile`,
        component: React.lazy(() => import('views/dashboard-views/requests/ProfileRequests')),
        permissions: ['agent', 'agency','sub_agent','admin','user'],
    },
    {
        key: 'dashboard.requests.package',
        path: `${DASHBOARD_PREFIX_PATH}/requests/package`,
        component: React.lazy(() => import('views/dashboard-views/requests/PackageRequests')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.packages',
        path: `${DASHBOARD_PREFIX_PATH}/packages`,
        component: React.lazy(() => import('views/dashboard-views/packages')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.packages.add-package',
        path: `${DASHBOARD_PREFIX_PATH}/packages/add-package`,
        component: React.lazy(() => import('views/dashboard-views/packages/PackageForm')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.packages.edit-package',
        path: `${DASHBOARD_PREFIX_PATH}/packages/edit-package/:id`,
        component: React.lazy(() => import('views/dashboard-views/packages/PackageForm')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.listings',
        path: `${DASHBOARD_PREFIX_PATH}/listings`,
        component: React.lazy(() => import('views/dashboard-views/listings')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listings.add-listing',
        path: `${DASHBOARD_PREFIX_PATH}/listings/add-listing`,
        component: React.lazy(() => import('views/dashboard-views/listings/ListingForm')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listings.edit-listing',
        path: `${DASHBOARD_PREFIX_PATH}/listings/edit-listing/:id`,
        component: React.lazy(() => import('views/dashboard-views/listings/ListingForm')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.listing.addType',
        path: `${DASHBOARD_PREFIX_PATH}/listing/add-type`,
        component: React.lazy(() => import('views/dashboard-views/addType')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.specializations',
        path: `${DASHBOARD_PREFIX_PATH}/specializations`,
        component: React.lazy(() => import('views/dashboard-views/specializations')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.projects',
        path: `${DASHBOARD_PREFIX_PATH}/projects`,
        component: React.lazy(() => import('views/dashboard-views/projects')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.addresses',
        path: `${DASHBOARD_PREFIX_PATH}/addresses`,
        component: React.lazy(() => import('views/dashboard-views/addresses')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.agentAgency',
        path: `${DASHBOARD_PREFIX_PATH}/agent-agency`,
        component: React.lazy(() => import('views/dashboard-views/agentAgency')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.users',
        path: `${DASHBOARD_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/dashboard-views/users')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.subAgents',
        path: `${DASHBOARD_PREFIX_PATH}/sub-agents`,
        component: React.lazy(() => import('views/dashboard-views/subAgents')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.add-subagent',
        path: `${DASHBOARD_PREFIX_PATH}/add-subagent`,
        component: React.lazy(() => import('views/dashboard-views/subAgents/SubAgentForm')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.edit-subagent',
        path: `${DASHBOARD_PREFIX_PATH}/edit-subagent/:id`,
        component: React.lazy(() => import('views/dashboard-views/subAgents/SubAgentForm')),
        permissions: ['agency'],
    },
    {
        key: 'dashboard.badges',
        path: `${DASHBOARD_PREFIX_PATH}/badges`,
        component: React.lazy(() => import('views/dashboard-views/badges')),
        permissions: ['admin'],
    },
    {
        key: 'dashboard.recommendations',
        path: `${DASHBOARD_PREFIX_PATH}/recommendations`,
        component: React.lazy(() => import('views/dashboard-views/recommendations')),
        permissions: ['agent', 'agency','sub_agent','user'],
    },
    {
        key: 'dashboard.ratings',
        path: `${DASHBOARD_PREFIX_PATH}/ratings`,
        component: React.lazy(() => import('views/dashboard-views/ratings')),
        permissions: ['agent', 'agency','sub_agent','user'],
    },
    {
        key: 'dashboard.statusStory',
        path: `${DASHBOARD_PREFIX_PATH}/status-story`,
        component: React.lazy(() => import('views/dashboard-views/statusStory')),
        permissions: ['agent', 'agency','sub_agent'],
    },
    {
        key: 'dashboard.settings',
        path: `${DASHBOARD_PREFIX_PATH}/settings`,
        component: React.lazy(() => import('views/dashboard-views/settings')),
        permissions: ['agent', 'agency','sub_agent','admin'],
    },
]