const dev = {
	S3_AWS_IMAGES_PUBLIC_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
	S3_AWS_IMAGES_PRIVATE_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
	API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
	SERVER_URL: 'https://api.makaansaz.com',
	CLIENT_ID: '984215695893-i9saeagll5aeicfvppgucq1vpkq7lld9.apps.googleusercontent.com',
	APP_ID: '951031763543751'
};

const prod = {
	S3_AWS_IMAGES_PUBLIC_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
	S3_AWS_IMAGES_PRIVATE_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
	API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
	SERVER_URL: 'https://api.makaansaz.com',
	CLIENT_ID: '984215695893-i9saeagll5aeicfvppgucq1vpkq7lld9.apps.googleusercontent.com',
	APP_ID: '951031763543751'
};

const test = {
	S3_AWS_IMAGES_PUBLIC_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/public',
	S3_AWS_IMAGES_PRIVATE_URL: 'https://makaansaz.s3.ap-south-1.amazonaws.com/private',
	API_ENDPOINT_URL: 'https://api.makaansaz.com/api',
	SERVER_URL: 'https://api.makaansaz.com',
	CLIENT_ID: '984215695893-i9saeagll5aeicfvppgucq1vpkq7lld9.apps.googleusercontent.com',
	APP_ID: '951031763543751'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
