import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
const ProtectedRoute = () => {
	const { token } = useSelector(state => state.auth)
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/find-agents', { replace: true });
			window.location.reload();
        }
    }, [token, navigate]);

    return token ? <Outlet /> : null;
}

export default ProtectedRoute