import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes,openRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import AppRoute from './AppRoute';
import OpenRoutes from './OpenRoutes';

const Routes = () => {

	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				{protectedRoutes.map((route, index) => {
					return (
						<Route 
							key={route.key + index} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									permissions={route.permissions ?? []}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<OpenRoutes />}>
			{openRoutes.map(route => {
				return (
					<Route 
						key={route.path} 
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key} 
								component={route.component}
								permissions={[]}
								{...route.meta} 
							/>
						}
					/ >
				)
			})}
		</Route>
		</RouterRoutes>
	)
}

export default Routes